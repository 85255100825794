<template>
  <div class="main">
		<div class="main-top">
		  <div class="main-top-title">
		    <!-- 民政养老服务管理系统 -->
		    <img src="../image/logo2.png" alt="" />
		  </div>
		  <div class="main-top-option">
		  </div>
		  <div class="main-top-time">
		    <div class="newTime">
		      <div>
		        {{ newDay }}
		        <span style="margin-left: 10px">{{ newTime }}</span>
		      </div>
		    </div>
		  </div>
		</div>
		<div class="main-left">
		  <div class="item" style="flex: 0 0 auto; height: 200px;">
		    <div class="item-title">养老助餐概况</div>
		    <div class="item-main row">
		      <div class="content-item">
						<div class="content-title">助餐点</div>
						<div class="total"><span class="num">{{mealAssistanceInfo.assistance}}</span><span class="unit">个</span></div>
					</div>
		      <div class="content-item">
						<div class="content-title">助餐机构</div>
						<div class="total"><span class="num">{{mealAssistanceInfo.institution}}</span><span class="unit">个</span></div>
					</div>
		      <div class="content-item">
						<div class="content-title">累计就餐人数</div>
						<div class="total"><span class="num">{{mealAssistanceInfo.people}}</span><span class="unit">人</span></div>
					</div>
		    </div>
		    
		  </div>
		  <div class="item" style="height: 22%;">
		    <div class="item-title">老人统计</div>
		    <div class="item-chart flex">		
					<div class="content-chart">
		        <polyline-chart
		          simple
		          type="pie" :pieRadius="['70%','90%']"
		      		:series="oldInfo.data">
		        </polyline-chart>
					</div>
						<!-- <div class="content-right">
							<div><div class="color-area" style="background-color: #1363EB;"></div>孤寡老人 120次 45%</div>
							<div><div class="color-area" style="background-color: #FFC837;"></div>孤寡老人 120次 45%</div>
							<div><div class="color-area" style="background-color: #5A64F4;"></div>孤寡老人 120次 45%</div>
							<div><div class="color-area" style="background-color: #20CCF8;"></div>孤寡老人 120次 45%</div>
						</div> -->
						<div class="content-right" style="align-items:flex-start;overflow: auto;">
							<div
							v-for="(item,index) in oldInfo.data"
							:key="index"
							><div class="color-area" :style="{'backgroundColor': typeColors[index],}"></div>
							<div style="white-space: nowrap;">{{item.name}} {{item.value}}次 {{item.percentage}}%</div>
							</div>
						</div>
		    </div>
		  </div>
		  <div class="item" style="flex: 1 1 auto;"	>
		    <div class="item-title">热门助餐点</div>
		    <div class="item-chart">
		      <div class="warning-list">
		        <div class="warning-item" v-for="(item, idx) in institutionList" :key="idx"
		          @click="showWarningDetail(item.id)">
		          <img class="margin-right-normal"  :src="item.image" />
		          <div class="info" >
		            <div class="content">
		              <div class="item-name" >
		                <div>{{item.mealName}}</div>
										<div class="status">
											详情>>
										</div>
		              </div>
		              <div class="time-type">
		                <span>服务人员：{{item.people}}名</span>
		                <!-- <span>{{item.address}}</span> -->
		              </div>
		              <div class="position">地址：{{item.mealAddress}}</div>
		            </div>
		          </div>
		        </div>
		      </div>
		    </div>
		  </div>
		</div>
		<div class="main-right">
		  <div class="item" style="height: 22%">
		    <div class="item-title">助餐概况</div>
		    <div class="item-main">
		      <div class="item-main-item">
		        <div class="item-main-item-top">早餐人数</div>
		        <div class="item-main-item-bottom">{{sistanceSituationInfo.breakfast}}</div>
		      </div>
		      <div class="item-main-item">
		        <div class="item-main-item-top">午餐人数</div>
		        <div class="item-main-item-bottom">{{sistanceSituationInfo.lunch}}</div>
		      </div>
		      <div class="item-main-item">
		        <div class="item-main-item-top">晚餐人数</div>
		        <div class="item-main-item-bottom">{{sistanceSituationInfo.dinner}}</div>
		      </div>
		    </div>
		  </div>
		  <div class="item" style="height: 36%">
		    <div class="item-title">助餐点用餐情况</div>
		    <div class="item-chart points">
					<div class="points-list">
						<div class="points-item" :class="{'check': allPoints}" @click="pointsChanged()">全部</div>
						<div class="points-item" :class="{'check': item.check}" 
								v-for="(item, idx) in situationNames" :key="idx" @click="pointsChanged(item)">{{item.name }}</div>
					</div>
					<div class="points-chart">
		        <polyline-chart
		          type="bar" yName="人" circle :color="color"
		        	:xData="['用餐次数','用餐人数']"
		        	:series="situationInfo.series"
		        ></polyline-chart>
					</div>
		    </div>
		  </div>
		  <div class="item" style="height: 41%; overflow: auto;" >
		    <div class="item-title">就餐趋势</div>		    
		    <div class="item-chart">
		        <polyline-chart
		          type="line" yName="人" :color="color" area
		        	:xData="mealTrendInfo.xData"
		        	:series="mealTrendInfo.series"
		        ></polyline-chart>
				<div class="trend-chart-option">
					<div @click="trendClick(0)" :class="trendOption == 0 ? 'trend-option-item trend-active' : 'trend-option-item'">近一周</div>
					<div @click="trendClick(1)" :class="trendOption == 1 ? 'trend-option-item trend-active' : 'trend-option-item'">近一月</div>
				</div>
		    </div>
		  </div>
		</div>
		
		<div id="allmap">
		</div>	
		<mark-meal :show.sync="markShow" ref="markerWarning" @processEnded="processEnded"></mark-meal>

	</div>
	
</template>

<script>
	import * as echarts from "echarts";
	import polylineChart from "../components/polyline-chart.vue";
	import markMeal from "../components/mark-meal.vue"
	import {mealNum,
    mealInstitution,
    mealPeople,
    mealOld,
    mealAssistance,
    mealInstitutionInfo,
    mealAssistanceName,
    mealSituation,
    mealTrend} from "../api/mealApi"
	import markMealVue from '../components/mark-meal.vue';
	export default {
		components: {
			polylineChart,
			markMeal
		},
		data() {
			return {
				map: "",
				newDay: "2022.10.23",
				newTime: "18:25:53",
				color: [],
				visitListData: [{visitTime: '2023-12-12 12:12:12',personIdText: '张三',detailAddress:'新市街道',visitType: '现场'}],
				visitTypeReportData: [{name: '1', value: 12}, {name: '2', value: 23},{name: '3', value: 34},{name: '4', value: 45}],
				pointsList: [
					{id: 1, name: '助餐点A', persons: 10, address: '新市街道新达街社区xxxx号', image: '/image/equipment/gas.png'},
					{id: 2, name: '助餐点A', persons: 10, address: '新市街道新达街社区xxxx号', image: '/image/equipment/gas.png'},
					{id: 3, name: '助餐点A', persons: 10, address: '新市街道新达街社区xxxx号', image: '/image/equipment/gas.png'},
					{id: 4, name: '助餐点A', persons: 10, address: '新市街道新达街社区xxxx号', image: '/image/equipment/gas.png'},
					{id: 5, name: '助餐点A', persons: 10, address: '新市街道新达街社区xxxx号', image: '/image/equipment/gas.png'},
					{id: 6, name: '助餐点A', persons: 10, address: '新市街道新达街社区xxxx号', image: '/image/equipment/gas.png'},
					{id: 7, name: '助餐点A', persons: 10, address: '新市街道新达街社区xxxx号', image: '/image/equipment/gas.png'},
					{id: 8, name: '助餐点A', persons: 10, address: '新市街道新达街社区xxxx号', image: '/image/equipment/gas.png'},
					{id: 9, name: '助餐点A', persons: 10, address: '新市街道新达街社区xxxx号', image: '/image/equipment/gas.png'},
					{id: 10, name: '助餐点A', persons: 10, address: '新市街道新达街社区xxxx号', image: '/image/equipment/gas.png'},
					{id: 11, name: '助餐点A', persons: 10, address: '新市街道新达街社区xxxx号', image: '/image/equipment/gas.png'},
					{id: 12, name: '助餐点A', persons: 10, address: '新市街道新达街社区xxxx号', image: '/image/equipment/gas.png'},
					{id: 13, name: '助餐点A', persons: 10, address: '新市街道新达街社区xxxx号', image: '/image/equipment/gas.png'},
					{id: 14, name: '助餐点A', persons: 10, address: '新市街道新达街社区xxxx号', image: '/image/equipment/gas.png'},
				],
				allPoints: true,
				// 助餐点数据
				mealAssistanceInfo: {
					assistance: 0,
					institution: 0,
					people: 0,
				},
				// 老人统计
				oldInfo: {
					
				},
				typeColors: ["#1363EB", "#FFC837", "#5A64F4", "#20CCF8", "#F0882B", "#15CBFC"],
				// 热门助餐点
				institutionList: [
				],
				institutionInfo: {},
				// 助餐概况
				sistanceSituationInfo: {
					breakfast: 0,
					dinner: 0,
					lunch: 0
				},
				// 用餐情况
				situationNames: [],
				situationInfo: {},
				// 用餐趋势
				mealTrendInfo: {
					xData: [],
					series: [],
				},
				trendOption: 0,
				markShow: false,
			}
		},
		created() {
			this.m_mapLoader().then((AMap) => {
			  this.initMap();
			this.getAssistance()
			});
			setInterval(() => {
			  this.getDateTime();
			}, 1000);
			setTimeout(() => {
			this.$topGetToken(token=>{
				console.log('========')
				console.log(token)
			})
			}, 3000)
			setTimeout(() => {
				this.$topGetBigScreenPermissions(pers=>{
			})
			}, 5000)
			setInterval(() => {
			  this.getDateTime();
			}, 1000);
			this.createColors()
			this.getMealNum()
			this.getOldNum()
			this.getInstitutionInfo()
			this.getAssistanceNames()
			this.getMealTrend()
		},
		methods: {
			initMap() {
			  let map = this.m_initMap();
			  map.on("click", (e) => {
			    console.log("click", e);
			  });
			  this.map = map;
				
			},
			createColors() {
				this.color.push(new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: '#188df0' },
          { offset: 1, color: '#83bff6' }
        ]))
				this.color.push(new echarts.graphic.LinearGradient(0, 0, 0, 1, [
				  { offset: 0, color: '#E6A23C' },
				  { offset: 1, color: '#83bff6' }
				]))
			},
			getDateTime() {
				let yy = new Date().getFullYear();
				let mm = new Date().getMonth() + 1;
				let dd = new Date().getDate();
				let hh = new Date().getHours();
				let ms =
					new Date().getSeconds() < 10
						? "0" + new Date().getSeconds()
						: new Date().getSeconds();
				let mf =
					new Date().getMinutes() < 10
						? "0" + new Date().getMinutes()
						: new Date().getMinutes();
				this.newDay = yy + "." + mm + "." + dd;
				this.newTime = hh + ":" + mf + ":" + ms;
			},
			pointsChanged(info) {
				if (info) {
					this.allPoints = false
					this.situationNames.forEach(item => {
						if (item.check && item.id != info.id) {
							item.check = false
						}else if (item.id == info.id) {
							item.check = true
						}
					})
					this.getNameEchartsData(info.id)
				}else {
					this.allPoints = true
					this.situationNames.forEach(item => {
							item.check = false
					})
					this.getNameEchartsData()
				}				
			},
			processEnded(data) {
				console.log('processEnded', data)
				this.m_clearWarningMarker(this.map)
				this.warningShow = false
			},
			filterHandler(value, row, column) {
				
			},
			// 养老助餐概况
			getMealNum() {
				mealNum().then(res => {
					if(res.code == 0) {
						this.mealAssistanceInfo.assistance = res.info
					}
				})
				mealInstitution().then(res => {
					if(res.code == 0) {
						this.mealAssistanceInfo.institution = res.info
					}
				})
				mealPeople().then(res => {
					if(res.code == 0) {
						this.mealAssistanceInfo.people = res.info
					}
				})
			},
			// 老人统计
			getOldNum() {
				mealOld().then(res => {
					console.log(res)
					if(res.code == 0) {
						let total = 0
						res.info.forEach(item => {
							if(item.oldNumber) {
								total += item.oldNumber
							}
						})
						console.log('----->',total)
						let data = res.info.map(item => {
							let obj = {}
							obj.name = item.oldType
							if(item.oldNumber) {
								obj.value = item.oldNumber
								obj.percentage = Math.round((item.oldNumber/total)*100)
							}else {
								obj.value = 0
								obj.percentage = 0
							}
							// obj.percentage = item.probability
							return obj
						})
						this.oldInfo = {
							data: data
						}
					}
				})
			},
			// 热门助餐点
			getAssistance() {
				mealAssistance().then(res => {
					console.log(res)
					if(res.code == 0) {
						this.institutionList = res.info.map(item => {
							if(item.tradePhoto) {
								let imgs = JSON.parse(item.tradePhoto)
								let url = this.formatImageUrl(imgs[0].raw)
								item.image = url
							}else {
								item.image = '/image/equipment/gas.png'
							}
							return item
						})
						
						this.institutionList.forEach(item => {
							item.position = [item.lng, item.lat]
							item.name = item.mealName
							this.m_addMarker(this.map, item, () => {
								this.markShow = true;
								this.$refs.markerWarning.init(item.id)
							});
						})
						
					}
				})
			},
			// 助餐概况
			getInstitutionInfo() {
				mealInstitutionInfo().then(res => {
					console.log(res)
					if(res.code == 0) {
						this.sistanceSituationInfo.breakfast = res.info.breakfast
						this.sistanceSituationInfo.dinner = res.info.dinner
						this.sistanceSituationInfo.lunch = res.info.lunch
					}
				})
			},
			// 助餐点用餐情况
			getAssistanceNames() {
				mealAssistanceName().then(res => {
					console.log(res)
					if(res.code == 0) {
						this.situationNames = res.info.map(item => {
							let obj = {}
							obj.name = item.mealName
							obj.id = item.id
							return obj
						})
					}
				})
				this.getNameEchartsData()
			},
			// 获取用餐点echars数据
			getNameEchartsData(id) {
				mealSituation({
					id: id ? id : ''
				}).then(res => {
					console.log(res)
					if(res.code == 0) {
						this.situationInfo = {}
						this.situationInfo.series = [
							{name: '本月',data: [res.info[1].numberMeals,res.info[1].oldNumber]},
							{name: '上月',data: [res.info[0].numberMeals,res.info[0].oldNumber]}
						]
					}
				})
			},
			// 就餐趋势
			getMealTrend() {
				mealTrend({str: 1}).then(res => {
					console.log(res)
					if(res.code == 0 && res.info) {
						let xData = []
						let series = [
							{name: '特困老人',data: []},
							{name: '政府老人',data: []},
							{name: '特殊家庭老人',data: []},
							{name: '低保老人',data: []},
							{name: '一般老人',data: []},
						]
						
						res.info.forEach(item => {
							let day = new Date(item.oldDate.split(' ')[0]).getDate()
							xData.push(day)
							series[0].data.push(item.oldNumber.destituteOld)
							series[1].data.push(item.oldNumber.governmentOld)
							series[2].data.push(item.oldNumber.specialOld)
							series[3].data.push(item.oldNumber.subsistenceOld)
							series[4].data.push(item.oldNumber.sameOld)
						})
						this.mealTrendInfo.xData = xData
						this.mealTrendInfo.series = series
					}
				})
			},
			trendClick(val) {
				this.trendOption = val
			},
			// 详情弹窗
			showWarningDetail(id) {
				this.markShow = true
				this.$refs['markerWarning'].init(id)
			}
		}
	}
</script>

<style lang="scss">
@import "../assets/css/element-ui.css";
@import "../assets/css/index.scss";
@import "../assets/css/meal.scss";

</style>