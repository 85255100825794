<template>
  <div class="remark-main" v-if="show">
    <span class="border-color"></span>
    <span class="border-color"></span>
    <span class="border-color"></span>
    <span class="border-color"></span>
    <div class="close" @click="closePop">
      <i class="el-icon-close"></i>
    </div>
    <div class="title">{{info.mealName}}</div>
    <!-- <div class="info">负责单位：张三</div> -->
    <div class="info">负责人员：{{info.responsibilityPeople}} ({{info.number}})</div>
    <!-- 切换 -->
    <div class="option">
      <div
        :class="
          optionType == 1 ? 'option-item option-item-active' : 'option-item'
        "
        @click="optionClick(1)"
      >
        机构资料
      </div>
      <div
        :class="
          optionType == 2 ? 'option-item option-item-active' : 'option-item'
        "
        @click="optionClick(2)"
      >
        当日菜品
      </div>
      <div
        :class="
          optionType == 3 ? 'option-item option-item-active' : 'option-item'
        "
        @click="optionClick(3)"
      >
        全景图
      </div>
    </div>
    <div v-if="optionType == 1" class="option-one" :style="{'maxHeight': maxHeight + 'px'}">
        <div class="info">服务人员数量：{{info.people}}人</div>
        <div class="info">就餐时间段：早上：08:00~09:30 中午：11:30~12:30 晚上：17:30~19:00</div>
        <div class="info">助餐点地址：{{info.mealAddress}}</div>
        <div class="info">公司证照：</div>
        <div class="info-img">
            <div class="info-img-item" v-for="(item,index) in cardImgs" :key="index">
                <img :src="item.url">
                <div class="img-title">-{{item.name}}-</div>
            </div>
        </div>
    </div>
    <div v-if="optionType == 2" class="option-two" :style="{'maxHeight': maxHeight + 'px'}">
        <div class="option-two-option">
            <div @click="timeOptionClick(1)" :class=" timeOption == 1 ? 'two-option-item two-option-item-active' : 'two-option-item'">早餐</div>
            <div @click="timeOptionClick(2)" :class=" timeOption == 2 ? 'two-option-item two-option-item-active' : 'two-option-item'">午餐</div>
            <div @click="timeOptionClick(3)" :class=" timeOption == 3 ? 'two-option-item two-option-item-active' : 'two-option-item'">晚餐</div>
        </div>
        <div class="option-two-img">
          <div class="option-two-img-item" v-for="(item,index) in foodlist" :key="index">
            <img :src="item.url">
            <div style="margin-left:4px">{{item.name}}</div>
            <div style="display:flex;">
              <p>价格：{{item.dishPrice}}</p>
            </div>
          </div>
        </div>
    </div>
    <div v-if="optionType == 3" class="option-three" :style="{'maxHeight': maxHeight + 'px'}">
      <div class="option-three-item">
        <img class="option-three-item-img" :src="imgSrc">
        <div class="option-three-item-option">
          <img @click="imgOption(index)" v-for="(item,index) in imgList" :key="index" :class="imgIdx == index ? 'option-item option-item-active' : 'option-item'" :src="item" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mealAssistanceDish, mealAssisInstitution } from "../api/mealApi";
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      optionType: 1,
      timeOption: 1,
      id: 0,
      cardImgs: [],
      imgList: [],
      imgSrc: '',
      imgIdx: 0,
      foodlist: [],
      info: {},
      maxHeight: 0,
    };
  },
  created() {
    this.maxHeight = document.documentElement.clientHeight - 340
  },
  methods: {
    init(id) {
      this.id = id
      this.optionType = 1
      this.timeOption = 1
      this.imgIdx = 0
      mealAssisInstitution({id:id}).then(res => {
        if(res.code == 0) {
          this.info = res.info
          console.log(this.info)
          this.cardImgs = []
          this.imgList = []
          let info = res.info
          if(info.tradePhoto) {
            let imgs = JSON.parse(info.tradePhoto)
            let url = this.formatImageUrl(imgs[0].raw)
            this.cardImgs.push({
              name: '营业执照',
              url: url
            })
          }
          if(info.cateringServiceLicense) {
            let imgs = JSON.parse(info.cateringServiceLicense)
            let url = this.formatImageUrl(imgs[0].raw)
            this.cardImgs.push({
              name: '餐饮服务许可证',
              url: url
            })
          }
          if(info.foodHygieneLicense) {
            let imgs = JSON.parse(info.foodHygieneLicense)
            let url = this.formatImageUrl(imgs[0].raw)
            this.cardImgs.push({
              name: '食品卫生许可证',
              url: url
            })
          }
          if(info.firePermit) {
            let imgs = JSON.parse(info.firePermit)
            let url = this.formatImageUrl(imgs[0].raw)
            this.cardImgs.push({
              name: '消费许可证',
              url: url
            })
          }
          if(info.restaurantEnvironment) {
            let imgs = JSON.parse(info.restaurantEnvironment)
            this.imgList = imgs.map(item => {
              let url = this.formatImageUrl(item.raw)
              return url
            })
            this.imgSrc = this.imgList[0]
          }
        }
        
      })
    },
    optionClick(val) {
      console.log(val);
      this.optionType = val;
      if(val == 2) {
        mealAssistanceDish({id:this.id}).then(res => {
          console.log(res)
          if(res.code == 0) {
            this.foodlist = res.info.map(item => {
                if(item.dishThumbnail) {
                  let imgs = JSON.parse(item.dishThumbnail)
                  let obj = {}
                  obj.name = item.name
                  obj.dishPrice = item.dishPrice
                  obj.url = this.formatImageUrl(imgs[0].raw)
                  return obj
                }
            })
          }
        })
      }
    },
    closePop() {
      this.$emit("update:show", false);
    },
    timeOptionClick(val) {
      this.timeOption = val
    },
    option() {},
    imgOption(idx) {
      this.imgIdx = idx
      this.imgSrc = this.imgList[idx]
    },
  },
};
</script>
<style lang="scss">
.remark-main {
  width: 500px;
  // height: 100px;
  background: rgba(1, 31, 17, 0.8);
  position: absolute;
  border: 1px solid #2bcefd;
  box-sizing: border-box;
  padding: 10px;
  top: 200px;
  left: calc(50% - 250px);
  z-index: 999;
  .border-color:nth-child(1),
  .border-color:nth-child(2),
  .border-color:nth-child(3) {
    position: absolute;
    // top: 0;
    left: -2px;
    display: block;
    // height: 60px;
    width: 4px;
    background: #2bcefd;
  }
  .border-color:nth-child(1) {
    height: 60px;
    top: 0;
  }
  .border-color:nth-child(2) {
    height: 30px;
    top: 90px;
  }
  .border-color:nth-child(3) {
    height: 60px;
    bottom: 0;
  }
  .border-color:nth-child(4) {
    display: block;
    background: #2bcefd;
    height: 4px;
    width: 30px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .close {
    display: block;
    position: absolute;
    background: rgba(40, 70, 140, 0.8);
    top: -20px;
    right: -2px;
    color: white;
  }
  .title {
    width: 80%;
    background: rgb(15, 135, 255);
    color: white;
    text-align: left;
    box-sizing: border-box;
    padding: 5px;
    padding-left: 10px;
  }
  .info {
    width: 100%;
    color: white;
    font-size: 12px;
    box-sizing: border-box;
    padding: 10px 10px 0 10px;
    // padding-left: ;
    text-align: left;
  }
  .option {
    display: flex;
    margin-top: 20px;
    .option-item {
      font-size: 12px;
      color: #fff;
      padding: 5px 10px;
      background: rgba(0, 131, 255, 0.23);
      cursor: pointer;
    }
    .option-item-active {
      border: 2px solid rgba(15, 135, 255, 0.8);
    }
  }
  .option-one {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    height: calc(100% - 40px);
    box-sizing: border-box;
    flex-wrap: wrap;
    padding: 20px 10px;
    padding-bottom: 30px;
    background: rgba(0, 131, 255, 0.23);
    .item-main-top {
      width: 100%;
      box-sizing: border-box;
      padding: 0 20px;
      display: flex;
      height: 30px;
      justify-content: space-between;
      .item-main-top-item {
        width: 24%;
        background: rgba(0, 86, 255, 0.6);
        font-size: 12px;
        display: flex;
        align-items: center;
        color: white;
        justify-content: center;
        .el-select {
          .el-input {
            .el-input__inner {
              background: none;
              color: white;
              text-align: center;
              border: none;
              padding-left: 5px;
              padding-right: 15px;
              font-size: 12px;
            }
            .el-input__suffix {
              right: -2px;
            }
          }
          input::-webkit-input-placeholder {
            color: #fff;
          }
          input::-moz-placeholder {
            color: #fff;
          }
          input:-ms-input-placeholder {
            color: #fff;
          }
        }
        .el-date-editor {
          .el-range-separator {
            color: #fff;
          }
        }
      }
    }
    .item-main-info {
      width: 100%;
      box-sizing: border-box;
      padding: 0 20px;
      padding-top: 10px;
      // height: calc(100% - 18% - 10px);
      height: 100%;
      overflow: auto;
      scrollbar-width: none;
      max-height: 310px;
      &::-webkit-scrollbar {
        display: none;
      }
      .item-main-info-item {
        height: 30px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        -ms-overflow-style: none;
        div {
          width: 24%;
          font-size: 12px;
          // display: flex;
          // align-items: center;
          color: white;
          // justify-content: center;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          line-height: 20px;
          cursor: pointer;
        }
        .unhandled {
          color: red;
        }
        .pending {
          color: rgb(15, 135, 255);
        }
      }
      .item-main-info-item:nth-child(2n) {
        background: rgba(0, 131, 255, 0.25);
      }
    }
  }
  .option-two {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
    flex-wrap: wrap;
    padding: 40px 10px;
    padding-bottom: 30px;
    background: rgba(0, 131, 255, 0.23);
    position: relative;
    .option-two-option {
        display: flex;
        padding: 5px 10px;
        background-color: rgba(0, 131, 255, 0.4);
        position: absolute;
        right: 20px;
        top: 20px;
        .two-option-item {
            font-size: 12px;
            color: #fff;
            padding: 0 10px;
            cursor: pointer;
        }
        .two-option-item-active {
          color: rgb(15, 135, 255);
        }
    }
    .option-two-img {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 100%;
      margin-top: 20px;
      .option-two-img-item {
        margin-right: 10px;
        width: calc(25% - 7.5px);
        background-color: #fff;
        margin-bottom: 10px;
        img {
          width: 100%;
          height: 80px;
        }
        div {
          font-size: 12px;
          color: #333;
          text-align: left;
          box-sizing: border-box;
          padding: 0 5px;
          p {
            transform: scale(0.8);
          }
        }
      }
      .option-two-img-item:nth-child(4n) {
        margin-right: 0px;
      }
    }
  }
}
.device-image {
  width: 80px;
  height: 80px;
}
.info-img {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 10px 0 10px;
    .info-img-item {
        width: calc(100%/3 - 30px/3);
        margin-bottom: 10px;
        margin-right: 15px;
    }
    .info-img-item:nth-child(3n) {
      margin-right: 0px;
    }
    img {
        width: 100%;
        height: 100px;
        background-color: #fff;
    }
    .img-title {
        font-size: 12px;
        color: #999;
    }
}
.option-three {
  overflow: auto;
  .option-three-item {
    position: relative;
    .option-three-item-img {
      width: 100%;
      height: 400px;
    }
    .option-three-item-option {
      position: absolute;
      width: 100%;
      height: 100px;
      left: 0;
      bottom: 0;
      background-color: rgba(41, 41, 41, 0.5);
      box-sizing: border-box;
      padding: 10px;
      display: flex;
      justify-content: flex-start;
      .option-item {
        height: 80px;
        width: 80px;
        margin-right: 10px;
        box-sizing: border-box;
        border: 1px solid #fff;
      }
      .option-item-active {
        border: 1px solid #2bcefd;
      }
    }
  }
}
</style>