import Ajax from './ajax'

const mealNum = () => {
    return Ajax({
        url: '/mealAllowance/mealaApplet/mealAssistanceList'
    })
}
const mealInstitution = (params) => {
    return Ajax({
        url: '/mealAllowance/mealaApplet/mealAssistanceInstitution',
        params: params
    })
}
const mealPeople = () => {
    return Ajax({
        url: '/mealAllowance/mealaApplet/mealAssistancePeopleList'
    })
}
const mealOld = () => {
    return Ajax({
        url: '/mealAllowance/mealaApplet/mealAssistanceOldList'
    })
}
const mealAssistance = () => {
    return Ajax({
        url: '/mealAllowance/mealaApplet/hotMealAssistance'
    })
}
const mealInstitutionInfo = () => {
    return Ajax({
        url: '/mealAllowance/mealaApplet/mealAssistanceSituation'
    })
}
const mealAssistanceName = () => {
    return Ajax({
        url: '/mealAllowance/mealaApplet/mealAssistanceName'
    })
}
const mealSituation = (params) => {
    return Ajax({
        url: '/mealAllowance/mealaApplet/mealSituation',
        params: params,
    })
}
const mealTrend = (params) => {
    return Ajax({
        url: '/mealAllowance/mealaApplet/mealAssistanceTrend',
        params: params
    })
}

const mealAssisInstitution = (params) => {
    return Ajax({
        url: '/mealAllowance/mealaApplet/mealAssisInstitution',
        params: params
    })
}
const mealAssistanceDish = (params) => {
    return Ajax({
        url: '/mealAllowance/mealaApplet/mealAssistanceDish',
        params: params
    })
}
export {
    mealNum,
    mealInstitution,
    mealPeople,
    mealOld,
    mealAssistance,
    mealInstitutionInfo,
    mealAssistanceName,
    mealSituation,
    mealTrend,
    mealAssisInstitution,
    mealAssistanceDish
}
